import { render, staticRenderFns } from "./ContactUsItem.vue?vue&type=template&id=7727ff48&scoped=true&"
import script from "./ContactUsItem.vue?vue&type=script&lang=js&"
export * from "./ContactUsItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7727ff48",
  null
  
)

export default component.exports